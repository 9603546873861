import Categories from './components/Categories';
import Jumbotron from './components/Jumbotron';
import Layout from './components/Layout';
import Suppliers from './components/Suppliers';
import Contact from './components/Contact';

const App = () => {
  return (
    <>
      <Layout show={false}>
        <Jumbotron/>
        <Categories/>
        <Contact/>
        <Suppliers/>
      </Layout>
    </>
  );
}

export default App;
