import React from 'react';
import peinture from "../../images/peinture.png";
import stratification from "../../images/stratification.png";
import containers from "../../images/containers.webp";
import produits from "../../images/vente.png";
import "./categories.scss";

const Categories = () => {
  return (
    <section id="categories" className="categories">
      <div className="center">
        <div className="card">
          <img
            src={peinture}
            alt="peinture bateau chalutier"
            className='painting'
          />
          <div className="description">
            <h2>Carénage - Antifouling - Calage de bateau</h2>
            <p>
              Nous prenons en charge le bateau pour le <strong>carénage</strong>, le nettoyage, la mise à nue, appliquer la primaire epoxy, puis l'<strong>antifouling</strong>, ainsi que le remplacement des anodes. 
            </p>
            <h2>Peinture</h2>
            <p>
              Réalisation de <strong>laque</strong> au pistolet !
            </p>
          </div>
        </div>
        <div className="card background_granville">
          <div className="description">
            <h2>Polyester & époxy</h2>
            <p>
              Nous pouvons <strong>réparer</strong> vos pieces en polyester et époxy. 
            </p>
            <h2>Hublot & Propulseur</h2>
            <p>
                Nous vous remplaçons vos anciens <strong>hublots</strong>. Besoin d'un propulseur ? On vous l'installe.
            </p>
            <h2>Stratification</h2>
            <p>
              Nous vous réparons vos pièces et pouvons aussi les créer sur mesures en fonctions de vos besoins.
            </p>
          </div>
          <img
            src={stratification}
            alt="stratification"
            className='stratification'
          />
        </div>
        <div className="card">
          <img
            src={produits}
            alt="vente de produit bateau"
            className='products'
          />
          <div className="description">
            <h2>Vente de produits</h2>
            <ul>
              <li>Produits d'entretiens</li>
              <li>Antifouling</li> 
              <li>Laque</li> 
            </ul>
          </div>
        </div>
        <div className="card background_fields">
          <div className="description">
            <h2>Location d'emplacements</h2>
            <p>
              Nous avons la place pour <strong>stocker</strong> vos embarcations en port à sec. 
            </p>
          </div>
          <img
            src={containers}
            alt="containers"
            className='containers'
          />
        </div>
      </div>
    </section>
  )
};


export default Categories;