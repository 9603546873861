import React from "react";
import styles from './contact.module.scss';

const Contact = () => {
  return (
    <>
      <section id="contact" className={styles.contact}>
        <div className={styles.card}>
          <h2>Contactez-nous</h2>
          <div className={ styles.details }>
            <div className={styles.detail}>
              <h3>Téléphone : </h3>
              <a href="tel:0620753173">06.20.75.31.73</a>
            </div>
            <div className={styles.detail}>
              <h3>E-mail : </h3>
              <a href="mailto:jcsailing@gmail.com">jcsailing@gmail.com</a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact;